/* eslint-disable jsx-a11y/iframe-has-title */
import React, { lazy, Suspense, useEffect, useState } from "react";
import LoadingComponent from "../../components/LoadingComponent";
import { getSucursales } from "../../services/catalogs/CatalogsService";

const SucursalesComponent = lazy(() =>
  import("../../components/home/SucursalesComponent")
);

const Select2Sucursales = lazy(() =>
  import("../../components/selects/Select2Sucursales")
);

const Home = (props) => {
  const [data, setData] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [state, setState] = useState({});
  const [frame, setFrame] = useState(null);

  useEffect(() => {
      getSucursales().then( async (response) => {
        if(response.status === 200){
          setData(response.data);
          setSucursales(response.data);
          setFrame(response.data[0]?.iframe);
        }
       });
  }, []);

  const handleFilter = (target) => {
    if (target.value === 0) {
      setFrame(data[1].iframe);
      setSucursales(data);
    } else {
      const sucursal = data.filter((item) => target.value === item.id);
        setFrame(sucursal[0].iframe);
        setSucursales(sucursal);
    }
      setState({ ...state, [target.name]: target.value });
  };

  return (
    <div className="pt-5">
      <div className="row d-wrap">
        <div className="col-12 col-md-6">
          <div className="col-12 col-md-12">
            <label htmlFor="sucursal" className="form-label">
              <h4 className="select-title">Sucursales</h4>
            </label>
            <Suspense fallback={LoadingComponent()}>
              <Select2Sucursales
                id="id_sucursal"
                name="id_sucursal"
                placeholder="Seleccione una opción"
                onChange={handleFilter}
                required
              />
            </Suspense>
          </div>
          <div className="col-12 col-md-12 pt-5">
            <Suspense fallback={LoadingComponent()}>
              <SucursalesComponent sucursales={sucursales} />
            </Suspense>
          </div>
        </div>
        <div className="col-12 col-md-6 pt-5">
          {frame && (
            <iframe
              src={frame}
              width="100%"
              height="100%"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="mapa"
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
